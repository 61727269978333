import { useMutation } from 'react-query'
import noop from 'lodash/noop'
import esAPI from 'services/esAPI/esAPI'

export const useVerifyOTPMutation = ({ onSuccess = noop } = {}) =>
  useMutation('verify-otp', (values) => esAPI('post', '/verify-otp', values), {
    onSuccess: (response) => {
      onSuccess(response)
    },
  })
