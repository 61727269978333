import { useMutation } from 'react-query'
import noop from 'lodash/noop'
import { errorToast, successToast } from 'services/Notification/toastNotifications'
import esAPI from 'services/esAPI/esAPI'

export const useRequestOTPMutation = ({ onSuccess = noop } = {}) =>
  useMutation('request-otp', (values) => esAPI('post', '/request-otp', values), {
    onSuccess: (response) => {
      response.success ? successToast(response.message) : errorToast(response.message)
      onSuccess(response)
    },
  })
