import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { useNeverBounceCheck } from 'api/neverbounce/useNeverBounceCheck'
import { useRequestOTPMutation, useVerifyOTPMutation } from 'api/otp'
import { Anchor } from 'components/typography'
import { FORM_ERROR } from 'final-form'
import { useUserContext } from 'hooks'
import Router from 'next/router'
import PhoneInput from '@components/form/ReactPhoneInput/ReactPhoneInput'
import {
  minLength2Required,
  requiredAndMaxChars,
  requiredEmail,
  requiredUsOrUkPhone,
} from '@components/form/validation'
import FormError from '@components/input/FormError/FormError'
import Input from '@components/input/Input/Input'
import { View } from '@components/layout'
import LoadingSection from '@components/status/Loading'
import { P } from '@components/typography'
import { MarketingButton } from '../components/MarketingButton'

const ButtonView = styled(View)`
  justify-content: center;
  min-height: 84px;
`

const errorMsgs = {
  otpVerification: 'OTP verification failed. Please try again.',
  otpRequest: 'Requesting OTP failed. Please submit form again.',
  leadRegistration: 'Lead registration failed.',
}

export const redirectSuccess = (successUrl) => Router.push(successUrl)

export const ModalForm = ({ source, otpEnabled, successUrl = '', buttonConfig }) => {
  const [otpRequested, setOTPRequested] = useState(false)
  const [reSendRequest, setReSendRequest] = useState(false)

  const { lead } = useUserContext()

  const { isValidEmail, emailCheckLoading, validateEmail } = useNeverBounceCheck()

  const { mutateAsync: onRequestOTP } = useRequestOTPMutation({
    onSuccess: ({ verified, success }) => (verified ? redirectSuccess(successUrl) : setOTPRequested(success)),
  })

  const { mutateAsync: onVerifyOTP } = useVerifyOTPMutation()

  const onSubmit = async (values) => {
    if (!otpEnabled) {
      try {
        await lead({ ...values, source })
        redirectSuccess(successUrl)
      } catch (error) {
        return { [FORM_ERROR]: error.message || errorMsgs.leadRegistration }
      }
      return
    }

    if (!otpRequested) {
      try {
        await onRequestOTP({ ...values, source })
      } catch (error) {
        return { [FORM_ERROR]: error.message || errorMsgs.otpRequest }
      }
      return
    }

    try {
      await onVerifyOTP(values)
      await lead({ ...values, verifiedPhone: true, source })
      redirectSuccess(successUrl)
    } catch (error) {
      return { [FORM_ERROR]: error.message || errorMsgs.otpVerification }
    }
  }

  const reSendOTP = () => {
    setOTPRequested(false)
    setReSendRequest(true)
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitError, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <View>
            {otpRequested ? (
              <Field name="otpCode" component={Input} placeholder="OTP code*" validate={requiredAndMaxChars(6)} />
            ) : reSendRequest ? (
              <Field name="phone" component={Input} componentClass={PhoneInput} validate={requiredUsOrUkPhone} />
            ) : (
              <View>
                <Field name="firstName" component={Input} placeholder="Firstname*" validate={minLength2Required} />
                <Field name="lastName" component={Input} placeholder="Lastname*" validate={minLength2Required} />
                <Field
                  name="email"
                  component={Input}
                  placeholder="Email*"
                  validate={requiredEmail}
                  onChange={validateEmail}
                  loading={emailCheckLoading}
                  isVerified={isValidEmail}
                />
                <Field name="phone" component={Input} componentClass={PhoneInput} validate={requiredUsOrUkPhone} />
              </View>
            )}

            <FormError submitting={submitting} error={submitError} />

            {otpRequested && (
              <P>
                If your details were correct, we've sent a code to the number ending in{' '}
                <strong>{values.phone.slice(-4)}</strong>. It's valid for 10 minutes.{' '}
                <Anchor onClick={reSendOTP}>Re-send the code if you haven't received</Anchor>
              </P>
            )}

            <ButtonView>
              {submitting ? (
                <LoadingSection size="md" />
              ) : (
                <MarketingButton
                  contentConfig={!otpRequested ? buttonConfig : { text: 'Verify Number' }}
                  {...buttonConfig}
                />
              )}
            </ButtonView>
          </View>
        </form>
      )}
    />
  )
}
