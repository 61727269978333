import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Button from '@components/input/Button'

const ButtonText = styled.p`
  padding-bottom: 0;
  ${media.md`
    padding-bottom: ${(props) => props.padBottom && props.theme.spacing.sm}px;
  `}
`

const SubButtonText = styled.span`
  display: none;
  ${media.md`
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.xxs}px;
    color: ${({ theme }) => theme.colors.white};
  `}
`

export const MarketingButton = ({ contentConfig, ...props }) => {
  const { text, subText } = contentConfig ?? {}

  if (!contentConfig) return null

  return (
    <Button {...props} style={{ zIndex: 2 }}>
      <ButtonText padBottom={!!subText}>{text}</ButtonText>
      {subText && <SubButtonText>{subText}</SubButtonText>}
    </Button>
  )
}
